/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the expert asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import { lazy } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load components asychronously.
 */
import { IRoute } from '..';

/*
 * Used to get access to the API types and requests
 */
import * as FaceMatchDtos from '../../dtos/FaceMatch.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/**
 * Asynchronous expert page wrapping component
 */
export const AsyncExpertWrapperComponent = lazy(() => import('../../pages/expert/ExpertWrapperPage/ConnectedExpertWrapperPage'));

/**
 * Asynchronous expert dashboard page component
 */
export const AsyncDashboardPage = lazy(() => import('../../pages/expert/DashboardPage/ConnectedDashboardPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncPatientPage = lazy(() => import('../../pages/expert/PatientPage/ConnectedPatientPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncReferencePatientPage = lazy(() => import('../../pages/expert/ReferencePatientPage/ConnectedReferencePatientPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncPatientSummaryPage = lazy(() => import('../../pages/expert/PatientSummaryPage/ConnectedPatientSummaryPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncPatientArchivePage = lazy(() => import('../../pages/expert/PatientArchivePage/ConnectedPatientArchivePage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncPatientAnalysisArchivePage = lazy(() => import('../../pages/expert/PatientAnalysisArchivePage/ConnectedPatientAnalysisArchivePage'));

/**
 * Asynchronous expert profile page component
 */
export const AsyncProfilePage = lazy(() => import('../../pages/expert/ProfilePage/ConnectedProfilePage'));

const expertRoute: IRoute =  {
        component: AsyncExpertWrapperComponent,
        path: '/expert',
        requiresAuthentication: true,
        requiredRoles: [
            FaceMatchDtos.Role.Expert,
            FaceMatchDtos.Role.SuperUser
        ],
        childRoutes: {
            referencePatient: {
                component: AsyncReferencePatientPage,
                path: '/expert/reference-patient/:studyNumber',
            },
            analysisArchive: {
                component: AsyncPatientAnalysisArchivePage,
                path: '/expert/patient/:studyNumber/archive/analysis',
            },
            patientArchive: {
                component: AsyncPatientArchivePage,
                path: '/expert/patient/:studyNumber/archive/matches',
            },
            patientInfo: {
                component: AsyncPatientPage,
                path: '/expert/patient/:studyNumber/information',
            },
            patient: {
                component: AsyncPatientSummaryPage,
                path: '/expert/patient/:studyNumber',
            },
            profile: {
                component: AsyncProfilePage,
                path: '/expert/profile',
            },
            dashboard: {
                component: AsyncDashboardPage,
                path: '/expert',
            },
        }
    };

export default expertRoute;