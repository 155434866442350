/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the Link component.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *		- ComponentType
 */
import * as React from 'react';

/*
 * Used to connect a component to the redux store.
 */
import { connect } from 'react-redux';

/*
 * Used to bind action creators to a component.
 */
import { Dispatch, bindActionCreators } from 'redux';

/*
 * Used to force the component to update on route changes. 
 */
import { withRouter } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for getting access to the App component.
 */
import App, { IAppProps } from './App';

/*
 * Used to get access to the authenticated person actions and state typings.
 */
import { authenticatedPersonActions, IAuthenticatedPersonState } from './store/reducers/common/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IConnectedAppProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/**
 * This function maps the redux store state to the component.
 * @param state The redux store state.
 * @param ownProps The properties assigned to the component.
 */
const mapStateToProps = (
	state: IAuthenticatedPersonState,
	ownProps: IConnectedAppProps
): Partial<IAppProps> => {
	return {
		authenticatedPerson: state.authenticatedPerson.data,
		authenticatedPersonLoadState: state.authenticatedPerson.loadState
	};
};

const mapDispatchToProps = (dispatch: Dispatch): Partial<IAppProps> => {
	return bindActionCreators(
		{
			loadAuthenticatedPerson: authenticatedPersonActions.load
		},
		dispatch
	);
}

/*
 * ---------------------------------------------------------------------------------
 * Contants
 * ---------------------------------------------------------------------------------
 */

const ConnectedApp: React.ComponentType<IConnectedAppProps> = connect(
	mapStateToProps,
	mapDispatchToProps
)(App);

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default withRouter(ConnectedApp as any);
