/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the main react application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a react pure component.
 */
import * as React from 'react';

/*
 * Used to attach the imported styles to the created react component.
 */
import injectSheet from 'react-jss';

/*
 * Used to define the routing within the component and to make sure that the 
 * component rerenders every time the route changes.
 */
import { Switch, Redirect, RouteComponentProps, match } from 'react-router';

//import 'react-table/react-table.css';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for getting access to and typing the available styles.
 */
import { IAppClasses, AppClasses } from './App.jss';

/*
 * Used to get access to asychronous routes.
 */
import routes from './routes/routes';

/*
 * Used to setup icon library.
 */
import './icons';

/*
 * Used to setup routing logic.
 */
import './store/reducers/common/routing';

/*
 * Used to get access to the authenticated person actions.
 */
import { authenticatedPersonActions } from './store/reducers/common/authenticatedPerson';

/*
 * Used to get access to the API types and requests
 */
import * as FaceMatchDtos from './dtos/FaceMatch.dtos';

/*
 * Used for typings:
 *      - IRequestState
 */
import { IRequestState } from './types/IRequestState';

/*
 * Used to get access to the enumeration of request states. 
 */
import { RequestState } from './types/RequestState';

/*
 * Used to create route components from the static route definitions
 */
import { createRoutes } from './routes';

/*
 * Used for typing dictionaries. 
 */
import { IDictionary } from './types/IDictionary';

/*
 * Used to check if the authenticated user has specific roles. 
 */
import { hasRole } from './utils/common/authenticatedPerson';
import { Suspense } from 'react';
import Loading from './components/common/Loading/Loading';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Assets
 * ---------------------------------------------------------------------------------
 */

// const AppleTouchIcon57 = require('./assets/images/favicon/apple-touch-icon-57x57.png');
// const AppleTouchIcon114 = require('./assets/images/favicon/apple-touch-icon-114x114.png');
// const AppleTouchIcon72 = require('./assets/images/favicon/apple-touch-icon-72x72.png');
// const AppleTouchIcon144 = require('./assets/images/favicon/apple-touch-icon-144x144.png');
// const AppleTouchIcon60 = require('./assets/images/favicon/apple-touch-icon-60x60.png');
// const AppleTouchIcon120 = require('./assets/images/favicon/apple-touch-icon-120x120.png');
// const AppleTouchIcon76 = require('./assets/images/favicon/apple-touch-icon-76x76.png');
// const AppleTouchIcon152 = require('./assets/images/favicon/apple-touch-icon-152x152.png');

// const Favicon196 = require('./assets/images/favicon/favicon-196x196.png');
// const Favicon96 = require('./assets/images/favicon/favicon-96x96.png');
// const Favicon32 = require('./assets/images/favicon/favicon-32x32.png');
// const Favicon16 = require('./assets/images/favicon/favicon-16x16.png');
// const Favicon128 = require('./assets/images/favicon/favicon-128.png');

// const MsApplicationIcon144 = require('./assets/images/favicon/mstile-144x144.png');
// const MsApplicationIcon70 = require('./assets/images/favicon/mstile-70x70.png');
// const MsApplicationIcon150 = require('./assets/images/favicon/mstile-150x150.png');
// const MsApplicationIconRectangle = require('./assets/images/favicon/mstile-310x150.png');
// const MsApplicationIcon310 = require('./assets/images/favicon/mstile-310x310.png');

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * Application component properties.
 */
export interface IAppProps extends RouteComponentProps {
    classes?: IAppClasses<string>;
    authenticatedPerson?: FaceMatchDtos.Person;
    authenticatedPersonLoadState?: IRequestState;
    loadAuthenticatedPerson?: typeof authenticatedPersonActions.load;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */


export const delay = 500;

const SuspenseFallback = () => {
    const [pastDelay, setPastDelay] = React.useState(false);

    React.useEffect(() => {
        const timerId = window.setTimeout(() => {
            setPastDelay(true)
        }, delay);

        return () => {
            window.clearTimeout(timerId);
        }
    })

    if (pastDelay) {
        return (
            <Loading
                overlay={true}
                text={"Loading Resources"}
                color={"primary"}
            />
        )
    }

    return null;
};

/**
 * Application that handles the routing of the application.
 */
class App extends React.PureComponent<IAppProps> {
    constructor(props: IAppProps) {
        super(props);

        App.loadData(props);
    }

    static serverLoadData(store, match: match, query: IDictionary<string>)
    {
        this.loadData({
            match: match,
            history: undefined,
            location: undefined,
            authenticatedPerson: undefined,
            authenticatedPersonLoadState: undefined,
            loadAuthenticatedPerson: () => {
                return store.dispatch(authenticatedPersonActions.load());
            }
        });
    }

    static loadData(props: IAppProps) {
        const { authenticatedPersonLoadState, loadAuthenticatedPerson } = props;

        if (!authenticatedPersonLoadState ||
            authenticatedPersonLoadState.state === RequestState.None) {
            if (loadAuthenticatedPerson) {
                loadAuthenticatedPerson();
            }
        }
    }

    render() {

        return (
            <>
                {/* <Helmet>
                    <meta charSet="utf-8" />
                    <title>FaceMatch</title>
                    <link rel="apple-touch-icon-precomposed" sizes="57x57" href={AppleTouchIcon57} />
                    <link rel="apple-touch-icon-precomposed" sizes="114x114" href={AppleTouchIcon114} />
                    <link rel="apple-touch-icon-precomposed" sizes="72x72" href={AppleTouchIcon72} />
                    <link rel="apple-touch-icon-precomposed" sizes="144x144" href={AppleTouchIcon144} />
                    <link rel="apple-touch-icon-precomposed" sizes="60x60" href={AppleTouchIcon60} />
                    <link rel="apple-touch-icon-precomposed" sizes="120x120" href={AppleTouchIcon120} />
                    <link rel="apple-touch-icon-precomposed" sizes="76x76" href={AppleTouchIcon76} />
                    <link rel="apple-touch-icon-precomposed" sizes="152x152" href={AppleTouchIcon152} />
                    <link rel="icon" type="image/png" href={Favicon196} sizes="196x196" />
                    <link rel="icon" type="image/png" href={Favicon96} sizes="96x96" />
                    <link rel="icon" type="image/png" href={Favicon32} sizes="32x32" />
                    <link rel="icon" type="image/png" href={Favicon16} sizes="16x16" />
                    <link rel="icon" type="image/png" href={Favicon128} sizes="128x128" />
                    <meta name="application-name" content="FaceMatch"/>
                    <meta name="msapplication-TileColor" content="#FFFFFF" />
                    <meta name="msapplication-TileImage" content={MsApplicationIcon144} />
                    <meta name="msapplication-square70x70logo" content={MsApplicationIcon70} />
                    <meta name="msapplication-square150x150logo" content={MsApplicationIcon150} />
                    <meta name="msapplication-wide310x150logo" content={MsApplicationIconRectangle} />
                    <meta name="msapplication-square310x310logo" content={MsApplicationIcon310} />
                </Helmet> */}
                <Switch>
                    {this.renderRoutes()}
                </Switch>
            </>
        );
    }

    renderRoutes() {
        const { 
            authenticatedPerson, 
            authenticatedPersonLoadState,
            location
        } = this.props;

        if (!authenticatedPersonLoadState || 
            authenticatedPersonLoadState.state === RequestState.None ||
            authenticatedPersonLoadState.state === RequestState.Pending) {
                return null
            }

        let redirect = (
            <Redirect 
                path="/" 
                exact
                to={routes.public.childRoutes.home.path} 
            />
        );

        if (authenticatedPerson) {
            redirect = (
                <Redirect 
                    path="/"
                    exact
                    to={routes.public.childRoutes.registration.path} 
                />
            );
        }

        const superUser: boolean = hasRole(authenticatedPerson, FaceMatchDtos.Role.SuperUser);

        if (hasRole(authenticatedPerson,FaceMatchDtos.Role.Parent) || 
            superUser) {
            redirect = (
                <Redirect 
                    path="/"
                    exact
                    to={routes.parent.childRoutes.dashboard.path} 
                />
            );
        }

        if (hasRole(authenticatedPerson,FaceMatchDtos.Role.Doctor) || 
            superUser) {
            redirect = (
                <Redirect 
                    path="/"
                    exact
                    to={routes.doctor.childRoutes.dashboard.path} 
                />
            );
        }

        if (hasRole(authenticatedPerson,FaceMatchDtos.Role.Expert) || 
            superUser) {
            redirect = (
                <Redirect 
                    path="/"
                    exact
                    to={routes.expert.childRoutes.dashboard.path} 
                />
            );
        }

        if (hasRole(authenticatedPerson,FaceMatchDtos.Role.Administrator) || 
            superUser) {
            redirect = (
                <Redirect 
                    path="/"
                    exact
                    to={routes.administration.childRoutes.dashboard.path} 
                />
            );
        }

        return (
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>
                    {redirect}
                    {createRoutes(routes, authenticatedPerson, location)}
                </Switch>
            </Suspense>
        );
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default injectSheet(AppClasses)(App);