/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the parent asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import { lazy } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load components asychronously.
 */
import { IRoute } from '..';

/*
 * Used to get access to the API types and requests
 */
import * as FaceMatchDtos from '../../dtos/FaceMatch.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/**
 * Asynchronous parent page wrapping component
 */
export const AsyncParentWrapperComponent = lazy(() => import('../../pages/parent/ParentWrapperPage/ConnectedParentWrapperPage'));

/**
 * Asynchronous parent dashboard page component
 */
export const AsyncDashboardPage = lazy(() => import('../../pages/parent/DashboardPage/ConnectedDashboardPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncPatientPage = lazy(() => import('../../pages/parent/PatientPage/ConnectedPatientPage'));

/**
 * Asynchronous parent profile page component
 */
export const AsyncProfilePage = lazy(() => import('../../pages/parent/ProfilePage/ConnectedProfilePage'));

const parentRoute: IRoute =  {
        component: AsyncParentWrapperComponent,
        path: '/parent',
        requiresAuthentication: true,
        requiredRoles: [
            FaceMatchDtos.Role.Parent,
            FaceMatchDtos.Role.SuperUser
        ],
        childRoutes: {
            patientAcceptInvite: {
                component: AsyncPatientPage,
                path: '/parent/patient/add/:invitationGuid',
            },
            patientAdd: {
                component: AsyncPatientPage,
                path: '/parent/patient/add',
            },
            patient: {
                component: AsyncPatientPage,
                path: '/parent/patient/:studyNumber',
            },
            profile: {
                component: AsyncProfilePage,
                path: '/parent/profile',
            },
            dashboard: {
                component: AsyncDashboardPage,
                path: '/parent',
            }
            //enrolChildLater: {
            //    component: AsyncDashboardPage,
            //    path: '/parent/enrol-child-later',
            //}
        }
    };

export default parentRoute;