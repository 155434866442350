export const createGenerateClassName = () => {
    let count = 0;

    return (rule) => {
        if (count === 1000000) {
            count = 0;
        }

        count++;

        return `${rule.key}-${count}`;
    };
};