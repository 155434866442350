/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains functions to initialise the redux store.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create the router reducer and middleware.
 */
import {
	connectRouter,
	routerMiddleware as createRouterMiddleware
} from 'connected-react-router';

/*
 * Used to type objects:
 *      - History
 */
import { History } from 'history';

/*
 * Used to create the redux store and attach the required middleware.
 */
import {
	createStore,
	combineReducers,
	applyMiddleware,
	Reducer,
	Store,
	compose
} from 'redux';

/*
 * Used to create the redux middleware that handles running all side effect logic.
 */
import { createLogicMiddleware, Logic, LogicMiddleware } from 'redux-logic';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to attach new reducers and logic as they are registered in the registry.
 */
import reducerRegistry, { IReducers, ILogics } from './ReducerRegistry';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/**
 * This function is used to combine reducers while creating dummy reducers for any
 * property in the preload state that does not yet have a reducer loaded.
 * @param reducers reducers to be combined.
 * @param preloadState preloaded state.
 * @returns the combined reducer.
 */
const combine = (reducers: IReducers, history: History, preloadState?: any): Reducer => {
	const reducerNames = Object.keys(reducers);

	// Check if preloaded state references any reducers that have not yet been
	// registered and create a dummy reducer for the time being.
	if (preloadState) {
		Object.keys(preloadState).forEach(item => {
			if (reducerNames.indexOf(item) === -1) {
				reducers[item] = (state = null) => state;
			}
		});
	}

	// If no reducers are registered, create a placeholder reducer.
	if (Object.keys(reducers).length === 0) {
		return (state = null) => state;
	}

	reducers.router = connectRouter(history);

	return combineReducers(reducers);
};

/**
 * This function is used to combine logics.
 * @param logics logics to be combined.
 * @returns the combined logics.
 */
const combineLogics = (logics: ILogics): Logic[] => {
	const logicNames = Object.keys(logics);

	if (logicNames.length === 0) {
		return [];
	}

	let logic: Logic[] = [];

	for (let logicName of logicNames) {
		logic = logic.concat(logics[logicName]);
	}

	return logic;
};

/**
 * This function initialises the redux store and its associated middleware.
 * @param history The history to be used with the router middleware.
 * @param preloadState The preloaded state from the server.
 * @returns the store and logic middleware.
 */
export const createReduxStore = (
	history: History,
	preloadState?: any
): { store: Store; logicMiddleware: LogicMiddleware } => {
	// Create router middleware with the provided history.
	const routerMiddleware = createRouterMiddleware(history);

	// Create logic middleware with the currently registered logic.
	const logicMiddleware = createLogicMiddleware(
		combineLogics(reducerRegistry.getLogics())
	);

	// Combine all currently registered reducers with the router reducer.
	const reducer = 
		combine(reducerRegistry.getReducers(), history, preloadState);

	// Create middleware
	let middleware = null;

	if (process.env.NODE_ENV === 'production') {
		middleware = applyMiddleware(routerMiddleware, logicMiddleware);
	}
	else {
		if (process.env.NODE_TARGET === 'server') {
			middleware = applyMiddleware(routerMiddleware, logicMiddleware);
		} else {
			/*
			* Used to allow for logging of all redux actions.
			*/
			const logger = require('redux-logger').default;

			middleware = applyMiddleware(routerMiddleware, logicMiddleware, logger);
		}
	}
			
	const composeEnhancers = process.env.NODE_TARGET !== 'server' &&
		process.env.NODE_ENV !== 'production' &&
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
			(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({maxAge: 500}) : 
			compose;

	// Create store
	const store = createStore(reducer, preloadState as any, composeEnhancers(middleware));

	// Bind on reducer registration call back to the reducer registry to allow for
	// updating of reducers while the store is in use.
	reducerRegistry.setOnRegister((reducers, logics) => {
		store.replaceReducer(
			combine(reducers, history, preloadState)
		);
		logicMiddleware.mergeNewLogic(combineLogics(logics));
	});

	return {
		store: store,
		logicMiddleware
	};
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default createReduxStore;
