/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains functions to initialise the client application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a react pure component.
 */
import * as React from 'react';

/*
 * Used to provide the application with access to the style theme.
 */
import { ThemeProvider } from 'react-jss';

/*
 * Used to create a history object to store routing history.
 */
import { createBrowserHistory } from 'history';

/*
 * Used to create the redux store to be used in the application.
 */
import createReduxStore from './store/createStore';

import { createJssInstance } from './styles/jssInstance';

import { createGenerateClassName } from './styles/createGenerateClassNames';

import * as Tracking from './utils/common/analytics';

import { dom as fontAwesome } from '@fortawesome/fontawesome-svg-core';


/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * The theme that will be provided to the application.
 */
import DefaultTheme from './styles/themes/defaultTheme.jss'

/*
 * The react application that will be rendered.
 */
import App from './ConnectedApp';


/*
 * Used to provide the app with access to the redux store.
 */
import { Provider } from 'react-redux';

import ReactAI from 'react-appinsights';

import { ConnectedRouter } from 'connected-react-router';
import { render } from 'react-dom';
/*
 * Used to provide the app with access to a configured jss instance.
 */
const JssProvider = require('react-jss').JssProvider;

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * Application wrapper component properties.
 */
interface IAppWrapperProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const css = fontAwesome.css();
const head = document.head || document.getElementsByTagName('head')[0];
const style = document.createElement('style');

head.appendChild(style);

style.type = 'text/css';
style.appendChild(document.createTextNode(css));


const history = createBrowserHistory();

Tracking.initGa((window as any).GOOGLE_ANALYTICS_ID, (window as any).GOOGLE_ANALYTICS_DEBUG, history);

const { store } = createReduxStore(history);

const appInsightsInstrumentationKey: any = (window as any).APP_INSIGHTS_INSTRUMENTATION_KEY;

if (appInsightsInstrumentationKey) {
    ReactAI.init({ instrumentationKey: appInsightsInstrumentationKey }, history);
}

const jssInstance = createJssInstance();
const generateClassName = createGenerateClassName();

/**
 * Application wrapper that provides the style theme to the rest of the application.
 */
class AppWrapper extends React.Component<IAppWrapperProps> {
    render() {
        return (
            <Provider store={store}>
                <JssProvider 
                    jss={jssInstance} 
                    disableStylesGeneration={false} 
                    generateClassName={generateClassName}
                >
                    <ConnectedRouter history={history}>
                        <ThemeProvider theme={DefaultTheme}>
                            <App />
                        </ThemeProvider>
                    </ConnectedRouter>
                </JssProvider>
            </Provider>
        );
    }
}


render(
    <AppWrapper />,
    document.getElementById('root')
)

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default AppWrapper;