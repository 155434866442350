/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the doctor asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import { lazy } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load components asychronously.
 */
import { IRoute } from '..';

/*
 * Used to get access to the API types and requests
 */
import * as FaceMatchDtos from '../../dtos/FaceMatch.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/**
 * Asynchronous doctor page wrapping component
 */
export const AsyncDoctorWrapperComponent = lazy(() => import('../../pages/doctor/DoctorWrapperPage/ConnectedDoctorWrapperPage'));

/**
 * Asynchronous doctor dashboard page component
 */
export const AsyncDashboardPage = lazy(() => import('../../pages/doctor/DashboardPage/ConnectedDashboardPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncPatientPage = lazy(() => import('../../pages/doctor/PatientPage/ConnectedPatientPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncInvitePage = lazy(() => import('../../pages/doctor/InvitePage/ConnectedInvitePage'));

/**
 * Asynchronous parent profile page component
 */
export const AsyncProfilePage = lazy(() => import('../../pages/doctor/ProfilePage/ConnectedProfilePage'));

const doctorRoute: IRoute =  {
        component: AsyncDoctorWrapperComponent,
        path: '/doctor',
        requiresAuthentication: true,
        requiredRoles: [
            FaceMatchDtos.Role.Doctor,
            FaceMatchDtos.Role.SuperUser
        ],
        childRoutes: {
            invite: {
                component: AsyncInvitePage,
                path: '/doctor/invite',
            },
            patientAdd: {
                component: AsyncPatientPage,
                path: '/doctor/patient/add',
            },
            patient: {
                component: AsyncPatientPage,
                path: '/doctor/patient/:studyNumber',
            },
            profile: {
                component: AsyncProfilePage,
                path: '/doctor/profile',
            },
            dashboard: {
                component: AsyncDashboardPage,
                path: '/doctor',
            }
        }
    };

export default doctorRoute;