import * as ReactGA from 'react-ga';
import { History, Location } from 'history';

export const trackPageView = (location: Location): void => {
    ReactGA.set({
        page: location.pathname
    });
    ReactGA.pageview(location.pathname);
};

export const initGa = (googleAnalyticsId: string, debug: boolean, history: History): void => {
    ReactGA.initialize(googleAnalyticsId, {
        debug
    });

    if (debug) {
        ReactGA.set({
            sendHitTask: null
        });
    }

    trackPageView(history.location);
    history.listen(trackPageView);
};