/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file creates the JSS instance and initialises the plugins to be used.
 * --------------------------------------------------------------------------------
 */

import { create as createJss,  } from 'jss';
import jssNested from 'jss-nested';
import jssCamelCase from 'jss-camel-case';
import jssDefaultUnit from 'jss-default-unit';
import jssCompose from 'jss-compose';
import jssGlobal from 'jss-global';
import jssVendorPrefixer from 'jss-vendor-prefixer';

export const createJssInstance = () => {
    const jss = createJss();
    
    jss.use(
        (jssVendorPrefixer as any)(), 
        (jssNested as any)(), 
        (jssVendorPrefixer as any)(), 
        (jssCompose as any)(), 
        (jssDefaultUnit as any)(), 
        (jssCamelCase as any)(), 
        (jssGlobal as any)());
    
    return jss;
}