/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the Loading component.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to combine classNames together.
 */
import * as classNames from 'classnames';

/*
 * Used to create a react pure component.
 */
import * as React from 'react';

/*
 * Used to allow for creation with base jsx elements.
 */

/*
 * Used to attach the imported styles to the created react component.
 */
import injectSheet from 'react-jss';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      - ILoadingProps
 */
import { ILoadingProps } from '.';

/*
 * Used for getting access to the available styles.
 */
import { LoadingClasses, getAnimationDelay } from './Loading.jss';

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component handles the rendering of the Home page.
 */
class UnstyledLoading extends React.PureComponent<ILoadingProps> {

	render() {
		

        const {
			bars,
			text,
			className,
			classes,
			overlay
        } = this.props;

		const numberOfBars = bars ? Array(bars).fill({}) : Array(80).fill({});

        const loading = (
			<div
				className={classNames(classes.component, className)}
			>
				<div className={classes.animatedLoad}>
					<div className={classes.loading}>
						{
							numberOfBars.map((item, i) => {
								return (
									<div 
										key={i}
										className={classNames(classes.bar)}
										style={{
											animationDelay: getAnimationDelay(this.props, i)
										}}
									>
									</div>
								);
							})
						}
					</div>
				</div>
				{ 
					text ?
					<div
						className={classes.text}
					>
						{text}
					</div> :
					null
				}
			</div>
			
		);

		if (overlay) {
			return (
				<div
					className={classes.overlay}
				>
					{loading}
				</div>
			);
		}

		return loading;
	}
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const Loading: typeof UnstyledLoading = injectSheet(LoadingClasses)(UnstyledLoading);

export default Loading;
