/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains helper methods for an authenticated person object.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */
import * as FaceMatchDtos from '../../dtos/FaceMatch.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/**
 * This method checks if an authenticated person has a role.
 * @param person The authenticated person
 * @param role The role
 * @returns Whether the role was found
 */
export const hasRole = (person: FaceMatchDtos.Person, role: FaceMatchDtos.Role) => {
    if (!person || 
        !person.roles || 
        person.roles.length === 0) {
        return false;
    }

    if (person.roles.some(r => r.role === role)) {
        return true;
    }

    return false;
}