/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the default style theme for the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to generate Colors to be used in the default theme.
 */
import * as Color from 'color';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      - ITheme
 *      - IColors
 *      - IThemeColors
 */
import ITheme, { IColors, IThemeBreakpoints } from '.';

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/**
 * Base colurs used in the application.
 */
const colors: IColors = {
    darkRed: Color.rgb(231, 4, 15),
    red: Color.rgb(255, 65, 54),
    lightRed: Color.rgb(255, 114, 92),
    orange: Color.rgb(255, 99, 0),
    gold: Color.rgb(255, 183, 0),
    yellow: Color.rgb(255, 222, 55),
    lightYellow: Color.rgb(251, 241, 169),
    purple: Color.rgb(94, 44, 165),
    lightPurple: Color.rgb(164, 99, 242),
    darkPink: Color.rgb(213, 0, 143),
    hotPink: Color.rgb(255, 65, 180),
    pink: Color.rgb(255, 128, 204),
    lightPink: Color.rgb(255, 163, 215),
    darkGreen: Color.rgb(19, 119, 82),
    green: Color.rgb(25, 169, 116),
    lightGreen: Color.rgb(158, 235, 207),
    navy: Color.rgb(0, 27, 68),
    darkBlue: Color.rgb(0, 68, 158),
    blue: Color.rgb(53, 126, 221),
    lightBlue: Color.rgb(150, 204, 255),
    lightestBlue: Color.rgb(205, 236, 255),
    washedBlue: Color.rgb(246, 255, 254),
    washedGreen: Color.rgb(232, 253, 245),
    washedYellow: Color.rgb(255, 252, 235),
    washedRed: Color.rgb(255, 223, 223),
    white: Color.rgb(255, 255, 255),
    black: Color.rgb(0, 0, 0)
};

/**
 * The theme colours used in the application.
 */
const themeColors = {
    primary: Color.rgb(49, 114, 137),
    secondary: Color.rgb(252, 52, 104),
    tertiary: Color.rgb(57, 59, 59),
    quaternary: Color.rgb(255, 149, 110),
    //quaternary: Color.rgb(251,175,147),
    quinary: colors.darkGreen,
    senary: colors.darkBlue,
    light: Color.rgb(237, 240, 240),
    dark: Color.rgb(57, 59, 59),
    success: colors.green,
    warning: colors.yellow,
    information: colors.blue,
    danger: colors.red,
    body: Color.rgb(69, 71, 71)
};

const breakpoints: IThemeBreakpoints = {
    extraSmall: 0,
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200
}


/**
 * common spacing values.
 */
const spacing = {
    extraSmall: 10,
    small: 20,
    normal: 30,
    large: 40,
    extraLarge: 50
};

/**
 * The default style theme for the application.
 */
export const defaultTheme: ITheme = {
    colors: colors,
    themeColors: themeColors,
    public: {
        header: {
            container: {
                paddingX: spacing.normal,
                paddingY: spacing.small
            },
            links: {
                fontSize: 18,
                paddingX: spacing.normal,
                paddingY: spacing.extraSmall
            }
        }
    },
    fonts: {
        primary: {
            size: 16,
            family: 'Open Sans,sans-serif',
            style: 'normal',
            weight: 'normal'
        },
        secondary: {
            size: 16,
            family: 'Lato,sans-serif',
            style: 'normal',
            weight: 'normal'
        },
        monospace: {
            size: 16,
            family: 'Droid Sans Mono,sans-serif',
            style: 'normal',
            weight: 'normal'
        }
    },
    breakpoints: breakpoints,
    mediaQueries: {
        extraSmallOnly: `@media (min-width: ${breakpoints.extraSmall}px) and (max-width: ${(breakpoints.small - 1)}px)`,
        smallOnly: `@media (min-width: ${breakpoints.small}px) and (max-width: ${(breakpoints.medium - 1)}px)`,
        mediumOnly: `@media (min-width: ${breakpoints.medium}px) and (max-width: ${(breakpoints.large - 1)}px)`,
        largeOnly: `@media (min-width: ${breakpoints.large}px) and (max-width: ${(breakpoints.extraLarge - 1)}px)`,
        extraLargeOnly: `@media (min-width: ${breakpoints.extraLarge}px)`,
        extraSmallUp: `@media (min-width: ${breakpoints.extraSmall}px)`,
        smallUp: `@media (min-width: ${breakpoints.small}px)`,
        mediumUp: `@media (min-width: ${breakpoints.medium}px)`,
        largeUp: `@media (min-width: ${breakpoints.large}px)`,
        extraLargeUp: `@media (min-width: ${breakpoints.extraLarge}px)`,
        extraSmallDown: `@media (max-width: ${(breakpoints.small - 1)}px)`,
        smallDown: `@media (max-width: ${(breakpoints.medium - 1)}px)`,
        mediumDown: `@media (max-width: ${(breakpoints.large - 1)}px)`,
        largeDown: `@media (max-width: ${(breakpoints.extraLarge - 1)}px)`,
        extraLargeDown: `@media (min-width: 0px)`
    },
    spacing: spacing,
    columns: {
        paddingX: spacing.normal,
        paddingY: 0
    },
    containers: {
        paddingX: spacing.normal,
        paddingY: 0
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default defaultTheme;