/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the styles for the Loading component.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - external
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Imports - internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      - ILoadingClasses
 */
import { ILoadingClasses, ILoadingProps } from '.';

/*
 * Used for typing:
 *      - ITheme
 *      - JssStyle
 */
import { ITheme, JssStyle } from '../../../styles/themes';



import { themeColorLevel } from '../../../styles/themeColorLevel';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ILoadingKeyFrames {
	[index: string]: JssStyle
 }

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/**
 * This function generates the styles to be applied to the Loading component.
 * @param theme The current style theme being used by the application.
 * @returns The generated css styles.
 */
export const LoadingClasses = (
	theme: ITheme
): ILoadingClasses<JssStyle> & ILoadingKeyFrames => {
	return {
		component: {
			width: (props: ILoadingProps) => getContainerWidth(props),
			margin: 'auto'
		} as JssStyle,
        animatedLoad: {
			height: (props: ILoadingProps) => getContainerHeight(props),
			position: 'relative',

		} as JssStyle,
		loading: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			display: 'flex',
			justifyContent: 'space-between',
			height: (props: ILoadingProps) => getBarHeight(props),
			width: (props: ILoadingProps) => getContainerWidth(props),
		} as JssStyle,
		bar: {
			display: 'inline-block',
			height: (props: ILoadingProps) => getBarHeight(props),
			width: (props: ILoadingProps) => getBarWidth(props),
			borderRadius: (props: ILoadingProps) => getBarWidth(props),
			animation: (props: ILoadingProps) => `loading ${getAnimationTime(props)}s ease-in-out infinite`,
			
			'&:nth-child(odd)': {
                backgroundColor: (props: ILoadingProps) => themeColorLevel(theme.themeColors[props.color], -0.32).rgb().toString(),
			} as JssStyle,
			
			'&:nth-child(even)': {
                backgroundColor: (props: ILoadingProps) => themeColorLevel(theme.themeColors[props.color], -0.24).rgb().toString(),
			} as JssStyle
		} as JssStyle,
		text: {
			textAlign: 'center',
			whiteSpace: 'nowrap',
			color: (props: ILoadingProps) => themeColorLevel(theme.themeColors[props.color], 0.48).rgb().toString(),
			fontSize: (props: ILoadingProps) => getBarHeight(props),
			marginTop: theme.spacing.extraSmall
		} as JssStyle,
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 20,
			background: theme.colors.black.alpha(0.2).rgb().toString(),

			'& > *': {
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				background: theme.colors.white.rgb().toString(),
				padding: theme.spacing.small
			}
		},
		'@keyframes loading': {
			
			'0%': {
				transform: 'scale(1)'
			},
	
			'20%': {
				transform: (props: ILoadingProps) => `scale(1, ${getAnimationScale(props)})`
			},
	
			'40%': {
				transform: 'scale(1)'
			}
		} as JssStyle
	};
};

const getContainerWidth = (props: ILoadingProps) => {
	return props.width ? props.width : 240;
}

const getContainerHeight = (props: ILoadingProps) => {
	return props.height ? props.height : 30;
}

const getAnimationScale = (props: ILoadingProps) => {
	return  2.2;
}

const getBars = (props: ILoadingProps) => {
	return props.bars ? props.bars : 80;
}
const getMargin = (props: ILoadingProps) => {
	return props.margin ? props.margin : 1;
}

const getBarHeight = (props: ILoadingProps) => {

	return getContainerHeight(props) / getAnimationScale(props);
}

const getBarWidth = (props: ILoadingProps) => {
	return (getContainerWidth(props) - ((getBars(props) - 1) * getMargin(props))) / getBars(props);
}

const getAnimationTime = (props: ILoadingProps) => {
	return props.time ? props.time : 1;
}

export const getAnimationDelay = (props: ILoadingProps, index: number) => {
	const bars = getBars(props);
	const animationTime = getAnimationTime(props);
	const delayInterval = (animationTime * 0.4) / (bars - 1);

	return `${((index - 1) * delayInterval)}s`;
}