/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving the authenticated person.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Used to create logic based side effects for the redux store.
 */
import { Module as ReduxTypedModule } from 'redux-typed-modules';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

 /*
  * Used to register the logics so that they are included in the store. 
  */
import reducerRegistry from '../../ReducerRegistry';

/*
 * Used to get access to the API types and requests
 */
import * as FaceMatchDtos from '../../../dtos/FaceMatch.dtos';

/*
 * Used for typings:
 *      - IRequestState 
 */
import { IRequestState } from '../../../types/IRequestState';

/*
 * Used to get access to the enumeration of request states. 
 */
import { RequestState } from '../../../types/RequestState';

/*
 * Used to easily create new immutable data off existing immutable data. 
 */
import { update } from "../../immutabilityHelper";

/*
 * Used to make calls to the FaceMatch API. 
 */
import { createApiClient } from '../../../dtos/client';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialAuthenticatedPersonState {
    data?: FaceMatchDtos.Person;
    referrerUrl?: string;
    credentials?: FaceMatchDtos.LoginCredentials;
    impersonation?: boolean;
    responseMessage?: string;
    showEmailVerificationWarning: boolean;
    loadState: IRequestState;
    registerAsParentState: IRequestState;
    registerAsDoctorState: IRequestState;
    saveProfileState: IRequestState;
    verifyEmailState: IRequestState;
    resendVerificationState: IRequestState;
    authenticationState: IRequestState;
    resetPasswordEmailState: IRequestState;
    updatePasswordState: IRequestState;
}

export interface IAuthenticatedPersonState {
    authenticatedPerson: IInitialAuthenticatedPersonState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@authenticated_person';

export const types = {
    SET_EMAIL_WARNING_VISIBILITY: `${typeNamespace}/SET_EMAIL_WARNING_VISIBILITY`,
    LOAD: `${typeNamespace}/LOAD`,
    LOAD_SUCCESS: `${typeNamespace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNamespace}/LOAD_FAILURE`,
    REGISTER_AS_PARENT: `${typeNamespace}/REGISTER_AS_PARENT`,
    REGISTER_AS_PARENT_SUCCESS: `${typeNamespace}/REGISTER_AS_PARENT_SUCCESS`,
    REGISTER_AS_PARENT_FAILURE: `${typeNamespace}/REGISTER_AS_PARENT_FAILURE`,
    REGISTER_AS_DOCTOR: `${typeNamespace}/REGISTER_AS_DOCTOR`,
    REGISTER_AS_DOCTOR_SUCCESS: `${typeNamespace}/REGISTER_AS_DOCTOR_SUCCESS`,
    REGISTER_AS_DOCTOR_FAILURE: `${typeNamespace}/REGISTER_AS_DOCTOR_FAILURE`,
    SAVE_PROFILE: `${typeNamespace}/SAVE_PROFILE`,
    SAVE_PROFILE_SUCCESS: `${typeNamespace}/SAVE_PROFILE_SUCCESS`,
    SAVE_PROFILE_FAILURE: `${typeNamespace}/SAVE_PROFILE_FAILURE`,
    SAVE_PROFILE_ADMIN: `${typeNamespace}/SAVE_PROFILE_ADMIN`,
    SAVE_PROFILE_ADMIN_SUCCESS: `${typeNamespace}/SAVE_PROFILE_ADMIN_SUCCESS`,
    SAVE_PROFILE_ADMIN_FAILURE: `${typeNamespace}/SAVE_PROFILE_ADMIN_FAILURE`,
    VERIFY_EMAIL: `${typeNamespace}/VERIFY_EMAIL`,
    VERIFY_EMAIL_SUCCESS: `${typeNamespace}/VERIFY_EMAIL_SUCCESS`,
    VERIFY_EMAIL_FAILURE: `${typeNamespace}/VERIFY_EMAIL_FAILURE`,
    RESEND_VERIFICATION: `${typeNamespace}/RESEND_VERIFICATION`,
    RESEND_VERIFICATION_SUCCESS: `${typeNamespace}/RESEND_VERIFICATION_SUCCESS`,
    RESEND_VERIFICATION_FAILURE: `${typeNamespace}/RESEND_VERIFICATION_FAILURE`,
    AUTHENTICATE_USER: `${typeNamespace}/AUTHENTICATE_USER`,
    AUTHENTICATE_USER_SUCCESS: `${typeNamespace}/AUTHENTICATE_USER_SUCCESS`,
    AUTHENTICATE_USER_FAILURE: `${typeNamespace}/AUTHENTICATE_USER_FAILURE`,
    RESET_PASSWORD_EMAIL: `${typeNamespace}/RESET_PASSWORD_EMAIL`,
    RESET_PASSWORD_EMAIL_SUCCESS: `${typeNamespace}/RESET_PASSWORD_EMAIL_SUCCESS`,
    RESET_PASSWORD_EMAIL_FAILURE: `${typeNamespace}/RESET_PASSWORD_EMAIL_FAILURE`,
    UPDATE_PASSWORD: `${typeNamespace}/UPDATE_PASSWORD`,
    UDPATE_PASSWORD_SUCCESS: `${typeNamespace}/UDPATE_PASSWORD_SUCCESS`,
    UPDATE_PASSWORD_FAILURE: `${typeNamespace}/UPDATE_PASSWORD_FAILURE`,
    BULK_RESET_PASSWORD_EMAIL: `${typeNamespace}/BULK_RESET_PASSWORD_EMAIL`,
    BULK_RESET_PASSWORD_EMAIL_SUCCESS: `${typeNamespace}/BULK_RESET_PASSWORD_EMAIL_SUCCESS`,
    BULK_RESET_PASSWORD_EMAIL_FAILURE: `${typeNamespace}/BULK_RESET_PASSWORD_EMAIL_FAILURE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialAuthenticatedPersonState = {
    data: undefined,
    referrerUrl: undefined,
    credentials: undefined,
    showEmailVerificationWarning: true,
    impersonation: undefined,
    responseMessage: undefined,
    loadState: {
        state: RequestState.None
    },
    registerAsParentState: {
        state: RequestState.None
    },
    registerAsDoctorState: {
        state: RequestState.None
    },
    saveProfileState: {
        state: RequestState.None
    },
    verifyEmailState: {
        state: RequestState.None
    },
    resendVerificationState:  {
        state: RequestState.None
    },
    authenticationState: {
        state: RequestState.None
    },
    resetPasswordEmailState: {
        state: RequestState.None
    },
    updatePasswordState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Redux Module
 * ---------------------------------------------------------------------------------
 */

const reduxTypedModule = new ReduxTypedModule<IInitialAuthenticatedPersonState, {}>({
    initialState: initialState
});

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const authenticatedPersonActions = {
    /**
     * This action begins the request to retrieve the currently authenticated person
     */
    setEmailVerificationWarningVisibility: reduxTypedModule.createAction({
        type: types.SET_EMAIL_WARNING_VISIBILITY,
        action: (show: boolean) => ({ show }),
        reducer: (state, action) => update(
            state,
            {
                showEmailVerificationWarning: {
                    $set: action.show
                }
            }
        )
    }),
    /**
     * This action begins the request to retrieve the currently authenticated person
     */
    load: reduxTypedModule.createAction({
        type: types.LOAD,
        action: () => ({}),
        reducer: (state, action) => update(
            state,
            {
                loadState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    /**
     * This action stores the person in the authenticated person state after a 
     * successful load request
     */
    loadSuccess: reduxTypedModule.createAction({
        type: types.LOAD_SUCCESS,
        action: (person: FaceMatchDtos.Person, impersonation: boolean) => {
            return ({
                person,
                impersonation
            })
        },
        reducer: (state, action) => update(
            state,
            {
                data: {
                    $set: action.person
                },
                impersonation: {
                    $set: action.impersonation
                },
                loadState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        )
    }),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed load request
     */
    loadFailure: reduxTypedModule.createAction({
        type: types.LOAD_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                data: {
                    $set: undefined
                },
                impersonation: {
                    $set: undefined
                },
                loadState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
    /**
    * This action begins the request to register the currently authenticated person
    * as a parent
    */
   registerAsParent: reduxTypedModule.createAction({
       type: types.REGISTER_AS_PARENT,
       action: (person: FaceMatchDtos.Person, password: string,
           registerFromInvitation: boolean,
           invitationGuid: string) => ({
               person,
                password,
               registerFromInvitation,
               invitationGuid
       }),
       reducer: (state, action) => update(
           state,
           {
                registerAsParentState: {
                   $set: {
                       state: RequestState.Pending
                   }
               }
           }
       )
   }),
   /**
    * This action stores the person in the authenticated person state after a 
    * successful register as parent request
    */
   registerAsParentSuccess: reduxTypedModule.createAction({
       type: types.REGISTER_AS_PARENT_SUCCESS,
       action: (person: FaceMatchDtos.Person, response: FaceMatchDtos.ResponseStatus, responseMessage: string) => {
           return ({
               person, response, responseMessage
           })
       },
       reducer: (state, action) => update(
           state,
           {
               data: {
                   $set: action.person
               },
               registerAsParentState: {
                   $set: {
                       state: RequestState.Success,
                       responseStatus: action.response
                   }
               },
               responseMessage: {
                   $set: action.responseMessage
               }
           }
       )
   }),
   /**
    * This action stores the error in the authenticated person state after a 
    * failed register as parent request
    */
   registerAsParentFailure: reduxTypedModule.createAction({
       type: types.REGISTER_AS_PARENT_FAILURE,
       action: (responseMessage: string) => ({
           responseMessage
       }),
       reducer: (state, action) => update(
           state,
           {
               registerAsParentState: {
                   $set: {
                       state: RequestState.Failure
                   }
               },
               responseMessage: {
                   $set: action.responseMessage
               }
           }
       )
   }),
   /**
   * This action begins the request to register the currently authenticated person
   * as a doctor
   */
  registerAsDoctor: reduxTypedModule.createAction({
        type: types.REGISTER_AS_DOCTOR,
        action: (
            person: FaceMatchDtos.Person,
            password: string,
            invitationGuid: string
        ) => ({
                person,
                password,
            invitationGuid
        }),
      reducer: (state, action) => update(
          state,
          {
               registerAsDoctorState: {
                  $set: {
                      state: RequestState.Pending
                  }
              }
          }
      )
  }),
  /**
   * This action stores the person in the authenticated person state after a 
   * successful register as doctor request
   */
  registerAsDoctorSuccess: reduxTypedModule.createAction({
      type: types.REGISTER_AS_DOCTOR_SUCCESS,
      action: (person: FaceMatchDtos.Person, response: FaceMatchDtos.ResponseStatus, responseMessage: string) => {
          return ({
              person, response, responseMessage
          })
      },
      reducer: (state, action) => update(
          state,
          {
              data: {
                  $set: action.person
              },
              registerAsDoctorState: {
                  $set: {
                      state: RequestState.Success,
                      responseStatus: action.response
                  }
              },
              responseMessage: {
                  $set: action.responseMessage
              }
          }
      )
  }),
  /**
   * This action stores the error in the authenticated person state after a 
   * failed register as doctor request
   */
  registerAsDoctorFailure: reduxTypedModule.createAction({
      type: types.REGISTER_AS_DOCTOR_FAILURE,
      action: (responseMessage: string) => ({
          responseMessage
      }),
      reducer: (state, action) => update(
          state,
          {
            registerAsDoctorState: {
                  $set: {
                      state: RequestState.Failure
                  }
              },
              responseMessage: {
                  $set: action.responseMessage
              }
          }
      )
  }),
    /**
     * This action clears the authenticated person state.
     */
    clear: reduxTypedModule.createAction({
        type: types.CLEAR,
        action: () => ({
        }),
        reducer: (state, action) => ({ ...initialState })
    }),
    /**
    * This action begins the request to save the currently authenticated person's profile
    */
    saveProfile: reduxTypedModule.createAction({
        type: types.SAVE_PROFILE,
        action: (person: FaceMatchDtos.Person) => ({
                person
            }),
        reducer: (state, action) => update(
            state,
            {
                saveProfileState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    /**
     * This action stores the person in the authenticated person state after a 
     * successful save profile request
     */
    saveProfileSuccess: reduxTypedModule.createAction({
        type: types.SAVE_PROFILE_SUCCESS,
        action: (person: FaceMatchDtos.Person, responseStatus: FaceMatchDtos.ResponseStatus) => {
            return ({
                person,
                responseStatus
            })
        },
        reducer: (state, action) => update(
            state,
            {
                data: {
                    $set: action.person
                },
                saveProfileState: {
                    $set: {
                        state: RequestState.Success,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed save profile request
     */
    saveProfileFailure: reduxTypedModule.createAction({
        type: types.SAVE_PROFILE_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                saveProfileState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),

    saveProfileAdmin: reduxTypedModule.createAction({
        type: types.SAVE_PROFILE_ADMIN,
        action: (person: FaceMatchDtos.Person) => ({
            person
        }),
        reducer: (state, action) => update(
            state,
            {
                saveProfileState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    /**
     * This action stores the person in the authenticated person state after a 
     * successful save profile request
     */
    saveProfileAdminSuccess: reduxTypedModule.createAction({
        type: types.SAVE_PROFILE_ADMIN_SUCCESS,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => {
            return ({
                responseStatus
            })
        },
        reducer: (state, action) => update(
            state,
            {
                saveProfileState: {
                    $set: {
                        state: RequestState.Success,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
    saveProfileAdminFailure: reduxTypedModule.createAction({
        type: types.SAVE_PROFILE_ADMIN_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                saveProfileState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
    /**
    * This action begins the request to verify a users email address
    */
    verifyEmail: reduxTypedModule.createAction({
        type: types.VERIFY_EMAIL,
        action: (guid: string) => ({
                guid
            }),
        reducer: (state, action) => update(
            state,
            {
                verifyEmailState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    /**
     * This action stores the person in the authenticated person state after a 
     * successful verify email request
     */
    verifyEmailSuccess: reduxTypedModule.createAction({
        type: types.VERIFY_EMAIL_SUCCESS,
        action: () => {
            return ({
            })
        },
        reducer: (state, action) => update(
            state,
            {
                verifyEmailState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        )
    }),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed verify email request
     */
    verifyEmailFailure: reduxTypedModule.createAction({
        type: types.VERIFY_EMAIL_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                verifyEmailState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
    /**
    * This action begins the request to verify a users email address
    */
    resendVerification: reduxTypedModule.createAction({
        type: types.RESEND_VERIFICATION,
        action: () => ({
            }),
        reducer: (state, action) => update(
            state,
            {
                resendVerificationState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    /**
     * This action stores the person in the authenticated person state after a 
     * successful verify email request
     */
    resendVerificationSuccess: reduxTypedModule.createAction({
        type: types.RESEND_VERIFICATION_SUCCESS,
        action: () => {
            return ({
            })
        },
        reducer: (state, action) => update(
            state,
            {
                resendVerificationState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        )
    }),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed verify email request
     */
    resendVerificationFailure: reduxTypedModule.createAction({
        type: types.RESEND_VERIFICATION_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                resendVerificationState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),

    authenticateUser: reduxTypedModule.createAction({
        type: types.AUTHENTICATE_USER,
        action: (credentials: FaceMatchDtos.LoginCredentials) => ({
            credentials
        }),
        reducer: (state, action) => update(
            state,
            {
                authenticationState: {
                    $set: {
                        state: RequestState.Pending,
                    }
                }
            }
        )
    }),
    authenticateUserSuccess: reduxTypedModule.createAction({
        type: types.AUTHENTICATE_USER_SUCCESS,
        action: (person: FaceMatchDtos.Person) => {
            return ({
                person
            })
        },
        reducer: (state, action) => update(
            state,
            {
                data: {
                    $set: action.person
                },
                authenticationState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        )
    }),
    authenticateUserFailure: reduxTypedModule.createAction({
        type: types.AUTHENTICATE_USER_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                authenticationState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),

    resetPasswordEmail: reduxTypedModule.createAction({
        type: types.RESET_PASSWORD_EMAIL,
        action: (credentials: FaceMatchDtos.LoginCredentials) => ({
            credentials
        }),
        reducer: (state, action) => update(
            state,
            {
                resetPasswordEmailState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    resetPasswordEmailSuccess: reduxTypedModule.createAction({
        type: types.RESET_PASSWORD_EMAIL_SUCCESS,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                resetPasswordEmailState: {
                    $set: {
                        state: RequestState.Success,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
    resetPasswordEmailFailure: reduxTypedModule.createAction({
        type: types.RESET_PASSWORD_EMAIL_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                resetPasswordEmailState: {
                    $set: {
                        state: RequestState.Pending,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),

    /**
    * This action begins the request to verify a users email address
    */
    updatePassword: reduxTypedModule.createAction({
        type: types.UPDATE_PASSWORD,
        action: (personId: string, password: string, token: string) => ({
            personId,
            password,
            token
        }),
        reducer: (state, action) => update(
            state,
            {
                updatePasswordState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    /**
     * This action stores the person in the authenticated person state after a 
     * successful verify email request
     */
    updatePasswordSuccess: reduxTypedModule.createAction({
        type: types.UDPATE_PASSWORD_SUCCESS,
        action: (response: FaceMatchDtos.PostUpdatePasswordResponse) => {
            return ({
                response
            })
        },
        reducer: (state, action) => update(
            state,
            {
                updatePasswordState: {
                    $set: {
                        state: RequestState.Success,
                        responseStatus: action.response.responseStatus
                    }
                }
            }
        )
    }),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed verify email request
     */
    updatePasswordFailure: reduxTypedModule.createAction({
        type: types.UPDATE_PASSWORD_FAILURE,
        action: (response: FaceMatchDtos.PostUpdatePasswordResponse) => ({
            response
        }),
        reducer: (state, action) => update(
            state,
            {
                updatePasswordState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.response.responseStatus
                    }
                }
            }
        )
    }),
    bulkResetPasswordEmail: reduxTypedModule.createAction({
        type: types.BULK_RESET_PASSWORD_EMAIL,
        action: (credentials: FaceMatchDtos.LoginCredentials) => ({
            credentials
        }),
        reducer: (state, action) => update(
            state,
            {
                resetPasswordEmailState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    bulkResetPasswordEmailSuccess: reduxTypedModule.createAction({
        type: types.BULK_RESET_PASSWORD_EMAIL_SUCCESS,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                resetPasswordEmailState: {
                    $set: {
                        state: RequestState.Success,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
    bulkResetPasswordEmailFailure: reduxTypedModule.createAction({
        type: types.BULK_RESET_PASSWORD_EMAIL_FAILURE,
        action: (responseStatus: FaceMatchDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                resetPasswordEmailState: {
                    $set: {
                        state: RequestState.Pending,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
};



/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const authenticatedPersonApi = {
    /**
     * This function creates a request to the FaceMatch API for the currently
     * authenticated person.
     * @returns The created request as a promise.
     */
    load: (): Promise<FaceMatchDtos.GetAuthenticatedPersonResponse> => {
        //Create the request.
        let request: FaceMatchDtos.GetAuthenticatedPerson =
            new FaceMatchDtos.GetAuthenticatedPerson();

        //Send request.
        const response: Promise<FaceMatchDtos.GetAuthenticatedPersonResponse> =
            createApiClient().get(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },

    /**
     * This function creates a request to the FaceMatch API to register 
     * the person as a parent. 
     * @returns The created request as a promise.
     */
    registerAsParent: async (
        person: FaceMatchDtos.Person,
        password: string,
        registerFromInvitation: boolean,
        invitationGuid?: string
    ): Promise<FaceMatchDtos.RegisterAsParentResponse> => {
        //Create the request.
        let request: FaceMatchDtos.RegisterAsParent = 
            new FaceMatchDtos.RegisterAsParent();

        request.person = person;
        request.password = password;
        request.registerFromInvitation = registerFromInvitation;

        if (invitationGuid) {
            request.invitationGuid = invitationGuid;
        }

        //Send request.
        const response: Promise<FaceMatchDtos.RegisterAsParentResponse> = 
            createApiClient().post(request);

        if (!(await response).responseMessage) {
            let authRequest: FaceMatchDtos.Authenticate =
                new FaceMatchDtos.Authenticate();

            authRequest.userName = (await response).person.email;
            authRequest.password = password;
            authRequest.provider = "Credentials";
            authRequest.continue = "/";
            authRequest.accessToken = "";

            //Send request.
            createApiClient().post(authRequest);
        }

        //Return request observable (incomplete request, still processing).
        return response;
    },

    /**
     * This function creates a request to the FaceMatch API to register 
     * the person as a doctor. 
     * @returns The created request as a promise.
     */
    registerAsDoctor: async (
        person: FaceMatchDtos.Person,
        password?: string,
        invitationGuid?: string
    ): Promise<FaceMatchDtos.RegisterAsDoctorResponse> => {
        //Create the request.
        let request: FaceMatchDtos.RegisterAsDoctor = 
            new FaceMatchDtos.RegisterAsDoctor();

        request.person = person;

        if (password) {
            request.password = password;
        }

        if (invitationGuid) {
            request.invitationGuid = invitationGuid;
        }

        //Send request.
        const response: Promise<FaceMatchDtos.RegisterAsDoctorResponse> = 
            createApiClient().post(request);

        if (!(await response).responseMessage) {
            let authRequest: FaceMatchDtos.Authenticate =
                new FaceMatchDtos.Authenticate();

            authRequest.userName = (await response).person.email;

            if (password) {
                authRequest.password = password;
            }
            
            authRequest.provider = "Credentials";
            authRequest.continue = "/";
            authRequest.accessToken = "";

            //Send request.
            createApiClient().post(authRequest);
        }

        //Return request observable (incomplete request, still processing).
        return response;
    },

    /**
     * This function creates a request to the FaceMatch API to save 
     * the currently authenticated person as a parent. 
     * @returns The created request as a promise.
     */
    saveProfile: (
        person: FaceMatchDtos.Person
    ): Promise<FaceMatchDtos.SaveProfileResponse> => {
        //Create the request.
        let request: FaceMatchDtos.SaveProfile =
            new FaceMatchDtos.SaveProfile();

        request.person = person;

        //Send request.
        const response: Promise<FaceMatchDtos.SaveProfileResponse> =
            createApiClient().post(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },


    /**
     * This function creates a request to the FaceMatch API to save 
     * the currently authenticated person as a parent. 
     * @returns The created request as a promise.
     */
    saveProfileAdmin: (
        person: FaceMatchDtos.Person
    ): Promise<FaceMatchDtos.SaveProfileResponse> => {
        //Create the request.
        let request: FaceMatchDtos.SaveProfile =
            new FaceMatchDtos.SaveProfile();

        request.person = person;

        //Send request.
        const response: Promise<FaceMatchDtos.SaveProfileResponse> =
            createApiClient().post(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },

    /**
     * This function creates a request to the FaceMatch API to save 
     * the currently authenticated person as a parent. 
     * @returns The created request as a promise.
     */
    verifyEmail: (
        guid: string
    ): Promise<FaceMatchDtos.VerifyEmailByGuidResponse> => {
        //Create the request.
        let request: FaceMatchDtos.VerifyEmailByGuid =
            new FaceMatchDtos.VerifyEmailByGuid();

        request.guid = guid;

        //Send request.
        const response: Promise<FaceMatchDtos.VerifyEmailByGuidResponse> =
            createApiClient().post(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },

    /**
     * This function creates a request to the FaceMatch API to save 
     * the currently authenticated person as a parent. 
     * @returns The created request as a promise.
     */
    resendVerification: (): Promise<FaceMatchDtos.ResendEmailVerificationResponse> => {
        //Create the request.
        let request: FaceMatchDtos.ResendEmailVerification =
            new FaceMatchDtos.ResendEmailVerification();

        //Send request.
        const response: Promise<FaceMatchDtos.ResendEmailVerificationResponse> =
            createApiClient().post(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },

    authenticateUser: async (
        credentials: FaceMatchDtos.LoginCredentials
    ): Promise<FaceMatchDtos.GetAuthenticatedPersonResponse> => {
        //Create the request.
        let request: FaceMatchDtos.Authenticate =
            new FaceMatchDtos.Authenticate();

        request.userName = credentials.email ? credentials.email : credentials.userId;
        request.password = credentials.password ? credentials.password : credentials.accessToken;
        request.provider = credentials.provider;
        request.accessToken = credentials.accessToken;

        //Send request.
        await createApiClient().post(request);

        let personRequest: FaceMatchDtos.GetAuthenticatedPerson = new FaceMatchDtos.GetAuthenticatedPerson();

        const response: Promise<FaceMatchDtos.GetAuthenticatedPersonResponse> =
            createApiClient().get(personRequest);

        //Return request observable (incomplete request, still processing).
        return response;
    },

    resetPasswordEmail: (
        credentials: FaceMatchDtos.LoginCredentials
    ): Promise<FaceMatchDtos.ResponseStatus> => {
        //Create the request.
        let request: FaceMatchDtos.ResetPasswordEmail =
            new FaceMatchDtos.ResetPasswordEmail();

        request.email = credentials.email;

        //Send request.
        const response: Promise<FaceMatchDtos.ResponseStatus> =
            createApiClient().post(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },

    updatePassword: (
        id: string,
        password: string,
        token: string
    ): Promise<FaceMatchDtos.PostUpdatePasswordResponse> => {
        //Create the request.
        let request: FaceMatchDtos.PostUpdatePassword =
            new FaceMatchDtos.PostUpdatePassword();

        request.personId = +id;
        request.password = password;
        request.token = token

        //Send request.
        const response: Promise<FaceMatchDtos.PostUpdatePasswordResponse> =
            createApiClient().post(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },
    bulkResetPasswordEmail: (
    ): Promise<FaceMatchDtos.ResponseStatus> => {
        //Create the request.
        let request: FaceMatchDtos.BulkResetPasswordEmail =
            new FaceMatchDtos.BulkResetPasswordEmail();

        //Send request.
        const response: Promise<FaceMatchDtos.ResponseStatus> =
            createApiClient().get(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },
 }

/*
 * ---------------------------------------------------------------------------------
 * Logics
 * ---------------------------------------------------------------------------------
 */
const 

    /**
     * This logic handles the request and response for loading user data.
     */
    loadLogic = createLogic({
        type: types.LOAD,
        latest: true,
        process: async ({getState, action}, dispatch, done) => {
            try {
                const response = await authenticatedPersonApi.load();
                dispatch(authenticatedPersonActions.loadSuccess(response.person, response.impersonation));
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.loadFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    }),
    /**
     * This logic handles the request and response to register the user as a parent.
     */
    registerAsParent = createLogic({
        type: types.REGISTER_AS_PARENT,
        latest: true,
        process: async ({getState, action}, dispatch, done) => {
            const typedAction: ReturnType<typeof authenticatedPersonActions.registerAsParent> = action as any;
            var response: any;
            try {
                response = await authenticatedPersonApi
                    .registerAsParent(
                        typedAction.person,
                        typedAction.password,
                        typedAction.registerFromInvitation,
                        typedAction.invitationGuid);

                if (response.responseMessage) {
                    dispatch(authenticatedPersonActions.registerAsParentFailure(response.responseMessage));
                }
                else {
                    dispatch(authenticatedPersonActions.registerAsParentSuccess(response.person, response.responseStatus, response.responseMessage));
                }
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.registerAsParentFailure(error ? error.responseStatus : response ? response : undefined));
            }

            done();
        }
    }),
    /**
     * This logic handles the request and response to register the user as a doctor.
     */
    registerAsDoctor = createLogic({
        type: types.REGISTER_AS_DOCTOR,
        latest: true,
        process: async ({getState, action}, dispatch, done) => {
            const typedAction: ReturnType<typeof authenticatedPersonActions.registerAsDoctor> = action as any;
            var response: any;
            try {
                response = await authenticatedPersonApi
                    .registerAsDoctor(
                        typedAction.person,
                        typedAction.password,
                        typedAction.invitationGuid);

                if (response.responseMessage) {
                    dispatch(authenticatedPersonActions.registerAsDoctorFailure(response.responseMessage));
                }
                else {
                    dispatch(authenticatedPersonActions.registerAsDoctorSuccess(response.person, response.responseStatus, response.responseMessage));
                }
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.registerAsDoctorFailure(error ? error.responseStatus : response ? response.responseStatus : undefined));
            }

            done();
        }
    }),
    /**
     * This logic handles the request and response to save the user's profile.
     */
    saveProfile = createLogic({
        type: types.SAVE_PROFILE,
        latest: true,
        process: async ({ getState, action }, dispatch, done) => {
            const typedAction: ReturnType<typeof authenticatedPersonActions.saveProfile> = action as any;

            try {
                const response = await authenticatedPersonApi
                    .saveProfile(
                        typedAction.person);

                dispatch(authenticatedPersonActions.saveProfileSuccess(response.person, response.responseStatus));
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.saveProfileFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    }),
    /**
     * This logic handles the request and response to save the user's profile.
     */
    saveProfileAdminLogic = createLogic({
        type: types.SAVE_PROFILE_ADMIN,
        latest: true,
        process: async ({ getState, action }, dispatch, done) => {
            const typedAction: ReturnType<typeof authenticatedPersonActions.saveProfileAdmin> = action as any;

            try {
                const response = await authenticatedPersonApi
                    .saveProfileAdmin(
                        typedAction.person);

                dispatch(authenticatedPersonActions.saveProfileAdminSuccess(response.responseStatus));
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.saveProfileAdminFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    }),
    /**
     * This logic handles the request and response to save the user's profile.
     */
    verifyEmail = createLogic({
        type: types.VERIFY_EMAIL,
        latest: true,
        process: async ({ getState, action }, dispatch, done) => {
            const typedAction: ReturnType<typeof authenticatedPersonActions.verifyEmail> = action as any;

            try {
                await authenticatedPersonApi
                    .verifyEmail(
                        typedAction.guid);

                dispatch(authenticatedPersonActions.verifyEmailSuccess());
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.verifyEmailFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    }),
    /**
     * This logic handles the request and response to save the user's profile.
     */
    resendVerification = createLogic({
        type: types.RESEND_VERIFICATION,
        latest: true,
        process: async ({ getState, action }, dispatch, done) => {
            //const typedAction: ReturnType<typeof authenticatedPersonActions.resendVerification> = action as any;

            try {
                await authenticatedPersonApi
                    .resendVerification();

                dispatch(authenticatedPersonActions.resendVerificationSuccess());
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.resendVerificationFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    }),

    authenticateLogic = createLogic({
        type: types.AUTHENTICATE_USER,
        latest: true,
        process: async ({ getState, action }, dispatch, done) => {
            const typedAction: ReturnType<typeof authenticatedPersonActions.authenticateUser> = action as any;
            var response: any;
            try {
                response = await authenticatedPersonApi
                    .authenticateUser(
                        typedAction.credentials);

                dispatch(authenticatedPersonActions.authenticateUserSuccess(response.person));
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.authenticateUserFailure(error ? error.responseStatus : response ? response : undefined));
            }

            done();
        }
    }),

    resetEmailLogic = createLogic({
        type: types.RESET_PASSWORD_EMAIL,
            latest: true,
            process: async ({ getState, action }, dispatch, done) => {
                const typedAction: ReturnType<typeof authenticatedPersonActions.resetPasswordEmail> = action as any;

                try {
                    const response = await authenticatedPersonApi
                        .resetPasswordEmail(
                            typedAction.credentials);

                    dispatch(authenticatedPersonActions.resetPasswordEmailSuccess(response));
                }
                catch (error: any) {
                    dispatch(authenticatedPersonActions.resetPasswordEmailFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
    }),

    updatePasswordLogic = createLogic({
        type: types.UPDATE_PASSWORD,
        latest: true,
        process: async ({ getState, action }, dispatch, done) => {
            const typedAction: ReturnType<typeof authenticatedPersonActions.updatePassword> = action as any;

            try {
                const response = await authenticatedPersonApi
                    .updatePassword(
                        typedAction.personId,
                        typedAction.password,
                        typedAction.token);

                dispatch(authenticatedPersonActions.updatePasswordSuccess(response));
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.updatePasswordFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    }),

    bulkResetEmailLogic = createLogic({
        type: types.BULK_RESET_PASSWORD_EMAIL,
        latest: true,
        process: async ({ getState, action }, dispatch, done) => {
            //const typedAction: ReturnType<typeof authenticatedPersonActions.bulkResetPasswordEmail> = action as any;

            try {
                const response = await authenticatedPersonApi
                    .bulkResetPasswordEmail();

                dispatch(authenticatedPersonActions.bulkResetPasswordEmailSuccess(response));
            }
            catch (error: any) {
                dispatch(authenticatedPersonActions.bulkResetPasswordEmailFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    }); 



const logics = [
    loadLogic,
    registerAsParent,
    registerAsDoctor,
    saveProfile,
    verifyEmail,
    resendVerification,
    authenticateLogic,
    resetEmailLogic,
    updatePasswordLogic,
    saveProfileAdminLogic,
    bulkResetEmailLogic
];

/*
 * ---------------------------------------------------------------------------------
 * Reducer
 * ---------------------------------------------------------------------------------
 */

const reducer = reduxTypedModule.createReducer();

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

reducerRegistry.register("authenticatedPerson", reducer, logics);

