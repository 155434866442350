
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import * as React from 'react';

/*
 * Used for typings
 */
import { RouteComponentProps, Route, Redirect } from 'react-router';

import { Location } from 'history';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      -IDictionary 
 */
import { IDictionary } from '../types/IDictionary';

/*
 * Used to get access to the API types and requests
 */
import * as FaceMatchDtos from '../dtos/FaceMatch.dtos';

import { hasRole } from '../utils/common/authenticatedPerson';
import Loading from '../components/common/Loading/Loading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IRoute
{
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path: string;
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
    childRoutes?: IDictionary<IRoute>;
    redirect?: string;

    /**
     * The roles required to access the route (OR condition).
     */
    requiresAuthentication?: boolean; 

    /**
     * The roles required to access the route (OR condition).
     */
    requiredRoles?: FaceMatchDtos.Role[];
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const loading = (props: any) => {

    if (props.pastDelay) {
        return (
            <Loading
                overlay={true}
                text={"Loading Resources"}
                color={"primary"}
            />
        )
    }

    return null;
};

export const delay = 500;

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const createRoutes = (routes: IDictionary<IRoute>, 
    authenticatedPerson: FaceMatchDtos.Person,
    location: Location) => {

    return (
        Object
            .keys(routes)
            .map(key => {
                const route = routes[key];

                //if route needs the user to be logged in.
                if (route.requiresAuthentication) {

                    //if no user logged in redirect to login.
                    if (!authenticatedPerson) {
                        const redirectLink = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
                        const loginLink = `/login?redirect=${redirectLink}`

                        return <Redirect
                            key={key}
                            to={loginLink}
                            path={route.path}
                            exact={route.exact}
                            strict={route.strict}
                        />
                    }

                    //if the route requires particular roles.
                    if (route.requiredRoles &&
                        route.requiredRoles.length > 0) {
    
                            // if the logged in user does no have those roles redirect to 403.
                            if (!authenticatedPerson.roles ||
                                authenticatedPerson.roles.length === 0 ||
                                !route.requiredRoles.some(r => hasRole(authenticatedPerson, r))) {
                                    return <Redirect
                                        key={key}
                                        to="/error/403"
                                        path={route.path}
                                        exact={route.exact}
                                        strict={route.strict}
                                    />
                                }
                        }
                }

                if (route.redirect) {
                    return (
                        <Redirect
                            key={key}
                            to={route.redirect}
                            path={route.path}
                            exact={route.exact}
                            strict={route.strict}
                        />
                    );
                }

                //If all authentication requirements pass, route to component.
                return (
                    <Route
                        key={key}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                        sensitive={route.sensitive}
                        strict={route.strict}
                    />
                );
            })
    )
}