/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains functions to initialise the redux store.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

 /*
  * Used to easily create new immutable data off existing immutable data. 
  */
 import { Context } from "immutability-helper";

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


const context = new Context();

export const update = context.update;

//Auto create missing objects.
context.extend('$auto', function (value, object) {
    return object ?
        update(object, value) :
        update({}, value);
});

//Auto create missing arrays.
context.extend('$autoArray', function (value, object) {
    return object ?
        update(object, value) :
        update([], value);
});