/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for scrolling after navigation.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

 /*
  * Used to register the logics so that they are included in the store. 
  */
import reducerRegistry from '../../ReducerRegistry';

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

export const types = {
    LOCATION_CHANGE: '@@router/LOCATION_CHANGE'
};

/*
 * ---------------------------------------------------------------------------------
 * Logics
 * ---------------------------------------------------------------------------------
 */

/**
 * This logic will cause the scroll bar to return to the top of the page on every
 * route.
 */
const navigateToTopLogic = createLogic({
    type: types.LOCATION_CHANGE,
    latest: true,
    process: ({getState, action}, dispatch, done) => {
        if (process.env.NODE_TARGET === "browser") {            
            const untypedAction = action as any;

            if (untypedAction && 
                untypedAction.payload && 
                untypedAction.payload.location &&
                untypedAction.payload.location.hash &&
                untypedAction.payload.location.hash.length > 1) {
                    const elementId = untypedAction.payload.location.hash.substring(1);

                    const element: HTMLElement = window.document.getElementById(elementId);

                    if (element) {
                        element.scrollIntoView();
                    }
                    else {
                        window.scrollTo({ top: 0, left: 0, behavior: 'auto'});
                    }
                }
            else {
                window.scrollTo({ top: 0, left: 0, behavior: 'auto'});
            }
            
            done();
        }
        else {
            done();
        }
    }
});

const logics = [
    navigateToTopLogic
];

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

reducerRegistry.register("routing", undefined, logics);

