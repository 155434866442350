/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file combines all the asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load components asychronously.
 */
import { IRoute, } from '..';

/*
 * Used for typing:
 *      -IDictionary 
 */
import { IDictionary } from '../../types/IDictionary';

/*
 * Used to get access to the public routes. 
 */
import publicRoute from './public';

/*
 * Used to get access to the administration routes. 
 */
import administrationRoute from './administration';

/*
 * Used to get access to the administration routes. 
 */
import parentRoute from './parent';

/*
 * Used to get access to the administration routes. 
 */
import doctorRoute from './doctor';

/*
 * Used to get access to the administration routes. 
 */
import expertRoute from './expert';

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const routes: IDictionary<IRoute> = 
    {
        administration: administrationRoute,
        parent: parentRoute,
        doctor: doctorRoute,
        expert: expertRoute,
        public: publicRoute
    };

export default routes;