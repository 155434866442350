import { JsonServiceClient } from '@servicestack/client';

export const createApiClient = (): JsonServiceClient => {

    const client = new JsonServiceClient();

    client.headers.set("Accept", "application/json")

    client.exceptionFilter = (response, error) => {
        if (response.status === 401 && window.location.pathname !== "/login") {
            window.location.href = `/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
        }
    }

    return client;
}