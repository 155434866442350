/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the styles for the main react application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to normalize styling in different browsers:
 */
import normalize from 'normalize-jss';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      - ITheme
 *      - JssStyle
 */

import { ITheme, JssStyle } from './styles/themes';

/*
 * ---------------------------------------------------------------------------------
 * Interface
 * ---------------------------------------------------------------------------------
 */

/**
 * The available classes on the App Component.
 */
export interface IAppClasses {}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/**
 * This function generates the styles to be applied to the App Component.
 * @param theme The current style theme being used by the application.
 * @returns The generated css styles.
 */
export const AppClasses = (
	theme: ITheme
): IAppClasses<JssStyle> => {
	return {
		...normalize,
		'@global html': {
			height: '100%',
			padding: 0,
			margin: 0,
			lineHeight: 1.375
		},
		'@global body': {
			height: '100%',
			padding: 0,
			margin: 0,
			fontFamily: theme.fonts.primary.family,
			fontSize: theme.fonts.primary.size,
			fontStyle: theme.fonts.primary.style,
			fontWeight: theme.fonts.primary.weight,
            color: theme.themeColors.body.rgb().toString(),
            overflowX: 'hidden'
		},
		'@global #root': {
			height: '100%',
			padding: 0,
			margin: 0
		},
		'@global p': {
			marginTop: 0
		},
		'@global p:last-child': {
			marginBottom: 0
		},
		// '@global li': {
		// 	marginBottom: theme.spacing.small
		// },
		// '@global li:last-child': {
		// 	marginBottom: 0
		// }
	};
};
