/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the public asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import { lazy } from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load components asychronously.
 */
import { IRoute } from '..';

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/**
 * Asynchronous home page component
 */
export const AsyncPublicWrapperComponent = lazy(() => import('../../pages/public/PublicWrapperPage/ConnectedPublicWrapperPage'));

/**
 * Asynchronous error page component
 */
export const AsyncErrorPage = lazy(() => import('../../pages/public/ErrorPage/ErrorPage'));

/**
 * Asynchronous login page component
 */
export const AsyncLoginPage = lazy(() => import('../../pages/public/LoginPage/ConnectedLoginPage'));

/**
 * Asynchronous home page component
 */
export const AsyncHomePage = lazy(() => import('../../pages/public/HomePage/HomePage'));

/**
 * Asynchronous for parents page component
 */
export const AsyncForParentsPage = lazy(() => import('../../pages/public/ForParentsPage/ForParentsPage'));

/**
 * Asynchronous for doctors page component
 */
export const AsyncForDoctorsPage = lazy(() => import('../../pages/public/ForDoctorsPage/ForDoctorsPage'));

/**
 * Asynchronous  meet the team page component
 */
export const AsyncMeetTheTeamPage = lazy(() => import('../../pages/public/MeetTheTeamPage/MeetTheTeamPage'));

/**
 * Asynchronous  advocates page component
 */
export const AsyncAdvocatePage = lazy(() => import('../../pages/public/AdvocatePage/AdvocatePage'));

/**
 * Asynchronous  publications page component
 */
export const AsyncPublicationsPage = lazy(() => import('../../pages/public/PublicationsPage/PublicationsPage'));

/**
 * Asynchronous contact us component
 */
export const AsyncContactUsPage = lazy(() => import('../../pages/public/ContactUsPage/ContactUsPage'));

/**
 * Asynchronous login component
 */
export const AsyncRegistrationPage = lazy(() => import('../../pages/public/RegistrationPage/RegistrationPage'));

/**
 * Asynchronous parent registration component
 */
export const AsyncParentRegistrationPage = lazy(() => import('../../pages/public/ParentRegistrationPage/ConnectedParentRegistrationPage'));

/**
 * Asynchronous doctor registration component
 */
export const AsyncDoctorRegistrationPage = lazy(() => import('../../pages/public/DoctorRegistrationPage/ConnectedDoctorRegistrationPage'));

/**
 * Asynchronous things to know component
 */
export const AsyncThingsToKnowPage = lazy(() => import('../../pages/public/ThingsToKnowPage/ThingsToKnowPage'));

/**
 * Asynchronous accept invitation component
 */
export const AsyncAcceptInvitationPage = lazy(() => import('../../pages/public/AcceptInvitationPage/ConnectedAcceptInvitationPage'));

/**
 * Asynchronous reject invitation component
 */
export const AsyncRejectInvitationPage = lazy(() => import('../../pages/public/RejectInvitationPage/ConnectedRejectInvitationPage'));

/**
 * Asynchronous email verification component
 */
export const AsyncEmailVerificationPage = lazy(() => import('../../pages/public/EmailVerificationPage/ConnectedEmailVerificationPage'));

/**
 * Asynchronous login page component
 */
export const AsyncPasswordResetPage = lazy(() => import('../../pages/public/PasswordResetPage/ConnectedPasswordResetPage'));

/**
 * Asynchronous parent patient page component
 */
export const AsyncInvitePage = lazy(() => import('../../pages/public/InvitePage/ConnectedInvitePage'));

const publicRoute: IRoute =  {
        component: AsyncPublicWrapperComponent,
        path: '/',
        childRoutes: {
            emailVerification: {
                component: AsyncEmailVerificationPage,
                path: '/email-verification/:emailGuid'
            },
            rejectInvitation: {
                component: AsyncRejectInvitationPage,
                path: '/invitation/reject/:invitationGuid'
            },
            acceptInvitation: {
                component: AsyncAcceptInvitationPage,
                path: '/invitation/accept/:invitationGuid',
                requiresAuthentication: true
            },
            parentRegistrationWithInvite: {
                component: AsyncParentRegistrationPage,
                path: '/registration/parent/:invitationGuid',
            },
            doctorRegistrationWithInvite: {
                component: AsyncDoctorRegistrationPage,
                path: '/registration/doctor/:invitationGuid',
            },
            parentRegistration: {
                component: AsyncParentRegistrationPage,
                path: '/registration/parent',
            },
            doctorRegistration: {
                component: AsyncDoctorRegistrationPage,
                path: '/registration/doctor',
            },
            registration: {
                component: AsyncRegistrationPage,
                requiresAuthentication: true, 
                path: '/registration'
            },
            loginWithInvite: {
                component: AsyncLoginPage,
                path: '/login/:invitationGuid',
            },
            login: {
                component: AsyncLoginPage,
                path: '/login'
            },
            thingsToKnow: {
                component: AsyncThingsToKnowPage,
                path: '/things-to-know'
            },
            contactUs: {
                component: AsyncContactUsPage,
                path: '/contact-us'
            },
            meetTheTeam: {
                component: AsyncMeetTheTeamPage,
                path: '/meet-the-team'
            },
            advocate: {
                component: AsyncAdvocatePage,
                path: '/facematch-advocates'
            },
            publications: {
                component: AsyncPublicationsPage,
                path: '/publications'
            },
            forDoctorsWithInvite: {
                component: AsyncForDoctorsPage,
                path: '/for-doctors/:invitationGuid'
            },
            forDoctors: {
                component: AsyncForDoctorsPage,
                path: '/for-doctors'
            },
            forParents: {
                component: AsyncForParentsPage,
                path: '/for-parents',
                redirect: '/home'
            },
            homeGuid: {
                component: AsyncHomePage,
                path: '/home/:invitationGuid'
            },
            home: {
                component: AsyncHomePage,
                path: '/home'
            },
            errorWithCode: {
                component: AsyncErrorPage,
                path: '/error/:errorCode'
            },
            passwordReset: {
                component: AsyncPasswordResetPage,
                path: '/password-reset/:id/:token',
            },
            passwordResetNoToken: {
                component: AsyncPasswordResetPage,
                path: '/password-reset/:id/',
            },
            passwordResetAuth: {
                component: AsyncPasswordResetPage,
                path: '/password-reset',
                requiresAuthentication: true, 
            },
            error: {
                component: AsyncErrorPage,
                path: '/error'
            },
            invite: {
                component: AsyncInvitePage,
                path: '/invite/4fdb5bfc931a48a18fbc7f219b2a875b',
            },
        }
    };

export default publicRoute;